import React from 'react';


const ProfileListItem = ({user}) => {
    return(
        <li className="collection-item avatar" key={user.id}>
            <img src={user.imgLink} alt="" className="circle" />
            <span className="title">{user.firstName} {user.lastName}</span>
            <p>{user.title} <br/>
                {user.institution}
            </p>
            <a href="#!" className="secondary-content"><i className="material-icons">rating</i></a>
        </li>
    )
}

export default ProfileListItem;