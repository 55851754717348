import React, {Component} from 'react';
import PostSummary from './PostSummary';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';


class PostList extends Component {
    render(){
        const { posts } = this.props;
        return(
            <div className='post-list section'>
                { posts && posts.map(post => {
                    return(
                        <Link to={'/dashboard/post/' + post.id} key={post.id}>
                            <PostSummary post={post} />
                        </Link>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        posts: state.firestore.ordered.posts,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
      { collection: 'posts', limit: 5, orderBy: ['createdAt', 'desc'] },
    ])
  )(PostList);