import React, {Component} from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, Switch, Route } from 'react-router-dom';


import Notifications from './Notifications';
import PostList from '../forum/PostList';
import ProfileSummary from '../profile/profileSummary';
import Profile from '../profile/profile';
import ProfileList from '../profile/profileList';
import CreatePost from '../forum/CreatePost';
import PostDetails from '../forum/PostDetails';
import ProfileUpdate from '../profile/profileUpdate';





class Dashboard extends Component {
    render(){
        const { posts, auth, notifications, profile } = this.props
        if (!auth.uid) return <Redirect to='/signin' />
        return(
            <div className='dashboard'>
                <div className='row'>
                    <div className='col s12 m8 offset-m1'>
                        <Switch>
                            <Route path='/dashboard/posts' component={PostList} props={{posts: posts}} />
                            <Route path='/dashboard/post/:id' component={PostDetails}/>
                            <Route path='/dashboard/createpost' component={CreatePost} />
                            <Route path='/dashboard/profile/update/:id' component={ProfileUpdate}/>
                            <Route path='/dashboard/profile/:id' component={Profile} />
                            <Route path='/dashboard/connect' component={ProfileList} />
                        </Switch>
                    </div>
                    <div className='col s5 m3'>
                        <ProfileSummary profile={profile} uid={auth.uid}/>
                        <Notifications notifications={notifications} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        posts: state.firestore.ordered.posts,
        auth: state.firebase.auth,
        notifications: state.firestore.ordered.notifications,
        profile: state.firebase.profile,
        users: state.firestore.ordered.users,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
      { collection: 'posts', limit: 5, orderBy: ['createdAt', 'desc'] },
      { collection: 'notifications', limit: 3, orderBy: ['time', 'desc']},
      { collection: 'users'},
    ])
  )(Dashboard)