import React, {Component} from 'react';
import './landing.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


class Landing extends Component {
    render() {
        const { auth } = this.props;
        if (auth.uid) return <Redirect to='/dashboard/posts' />
        return(
            <div id='landing'>
                <h1>Research Colab</h1>
                <p>BETA</p>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(Landing)