import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Dashboard from './components/dashboard/Dashboard';

import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';

import Footer from './components/layout/Footer';
import Landing from './views/landing/landing.js';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
          <main>
            <Switch>
              <Route exact path='/' component={Landing}/>
              <Route path='/dashboard' component={Dashboard}/>
              
              <Route path='/signin' component={SignIn} />
              <Route path='/signup' component={SignUp} />
            </Switch>
          </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
