import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

 // Your web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyAx2k1C4IWuApbsDB_gTnkmycUCDiU0p-Q",
    authDomain: "lxai-55b65.firebaseapp.com",
    databaseURL: "https://lxai-55b65.firebaseio.com",
    projectId: "lxai-55b65",
    storageBucket: "lxai-55b65.appspot.com",
    messagingSenderId: "45816411838",
    appId: "1:45816411838:web:f39bd27edacc322b"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({timestampsInSnapshots: true})

  export default firebase;