import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const Profile = (props) => {
    console.log(props)
    const { auth, user } = props
    console.log(user);
    if (!auth.uid) return <Redirect to='/signin' />
    if (user) {
        return(
            <div id='profile' >
                <img id='profile-header' className='responsive-img' src={user.headerImg} />
                <div className="col s12 m4 l3 user-section-negative-margin" >
                    <div className='row'>
                        <div className='col s12 center-align'>
                            <img id='profile-oval' src={user.imgLink} width="300" className='responsive-img circle z-depth-5' />
                            
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">person_add</i></a>
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">group_add</i></a>
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">add_alert</i></a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col s12 center-align'>
                            <p>{user.tagLine}</p>
                        </div>
                    </div>
                </div>
                <div className='col s12 m10 l6'>
                    <div >
                    <h3>{user.firstName} {user.lastName}</h3>
                    <h5>{user.title}, {user.institution}</h5>
                    <h6>{user.location}</h6>
                    </div>
                    <div class="card-panel grey">
                        <span class="white-text">
                            {user.bio}
                        </span>
                    </div>
                </div>
            </div>
        )
        } else {
            return (
                <div className='container center'>
                    <p>Loading user...</p>
                </div>
            )
        }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const users = state.firestore.data.users;
    const user = users ? users[id] : null;
    console.log(user);
    return {
        auth: state.firebase.auth,
        user: user
    }
}

export default compose(
    connect(mapStateToProps), 
    firestoreConnect([
       { collection: 'users'} 
    ])
)(Profile)