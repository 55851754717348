const initState = {
    profileError: null
  }

  const profileReducer = (state = initState, action) => {
    switch(action.type){
      case 'UPDATE_ERROR':
        console.log('update error');
        return {
          ...state,
          profileError: 'update failed'
        }
      case 'UPDATE_SUCCESS':
        console.log('update success');
        return {
          ...state,
          profileError: null
        }
      default:
        return state
    }
  };
  
  export default profileReducer;