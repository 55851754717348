import React from 'react';
import { Link } from 'react-router-dom';


const ProfileSummary = (props) => {
    const { profile, uid } = props;
    return(
        <div className='container profile-summary'>
            <div className="card">
                <div className="card-image">
                    <img alt='' src={ profile.imgLink } />
                    <span className="card-title grey darken-1">{ profile.firstName} { profile.lastName}</span>
                </div>
                <div className="card-content">
                    <p>{ profile.tagLine }</p>
                </div>
                <div className="card-action">
                    <ul id='profile-summary-action'>
                        <Link to={'/dashboard/profile/' + uid} key={uid}>
                            <li className='blue-text' uid={uid}>Profile</li>
                        </Link>
                            
                        <Link to={'/dashboard/profile/update/' + uid} key={'edit-' + {uid}}>
                            <li className='blue-text' uid={uid}>Edit</li>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProfileSummary