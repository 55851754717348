import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const SignedInLinks = (props) => {
    return(
       <ul className="right">
           <li><NavLink to='/dashboard/posts'>Forum</NavLink></li>
           <li><NavLink to='/dashboard/createpost'>Add Post</NavLink></li>
           <li><NavLink to='/dashboard/connect'>Connect</NavLink></li>
           <li><NavLink to='/'>Colab</NavLink></li>
           <li><a onClick={props.signOut}>Log Out</a></li>
           <li><NavLink to='/dashboard/profile' className='btn btn-floating lighten-1'>
               { props.profile.initials }
               </NavLink></li>
       </ul>
    )
}

const mapDispatchToProps =  (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);