export const updateProfile = (profile, uid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
      console.log(profile);
    //   console.log(uid);

      firestore.collection('users').doc(uid).update({
        firstName: profile.firstName,
        lastName: profile.lastName,
        initials: profile.firstName[0].toUpperCase() + profile.lastName[0].toUpperCase(),
        imgLink: profile.imgLink,
        headerImg: profile.headerImg,
        title: profile.title,
        institution: profile.institution,
        location: profile.location,
        specialization: profile.specialization,
        tagLine: profile.tagLine,
        bio: profile.bio
      }).then(() => {
        dispatch({ type: 'UPDATE_SUCCESS' })
      }).catch(err => {
        dispatch({ type: 'UPDATE_ERROR', err })
      })
    }
  }
  
  