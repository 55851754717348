import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import ProfileListItem from './ProfileListItem';
import { Link } from 'react-router-dom';

class ProfileList extends Component {
    render(){
        const { auth, users } = this.props
        if (!auth.uid) return <Redirect to='/signin' />
        return(
            <div className='container'>
            <ul className="collection">
                {users && users.map(user => {
                    return(
                        <Link to={'/dashboard/profile/' + user.id} key={user.id}>
                            <ProfileListItem user={user} />
                        </Link>
                    )
                    }
                )}
            </ul>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        auth: state.firebase.auth,
        users: state.firestore.ordered.users,
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect([
      { collection: 'users'},
    ])
  )(ProfileList);
