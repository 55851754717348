import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { updateProfile } from '../../store/actions/profileActions';


class ProfileUpdate extends Component {
    state = {
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        imgLink: this.props.imgLink,
        headerImg: this.props.headerImg,
        title: this.props.title,
        institution: this.props.institution,
        specialization: this.props.specialization,
        location: this.props.location,
        tagLine: this.props.tagLine,
        bio: this.props.bio
    }

    handleChange = (e) => {
        // console.log(this.state)
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const uid = this.props.auth.uid;
        const profileLink = '/dashboard/profile/' + uid;
        const profile = {
            firstName: this.state.firstName ? this.state.firstName : this.props.profile.firstName,
            lastName: this.state.lastName ? this.state.lastName : this.props.profile.lastName,
            imgLink: this.state.imgLink ? this.state.imgLink : this.props.profile.imgLink,
            headerImg: this.state.headerImg ? this.state.headerImg : this.props.profile.headerImg,
            title: this.state.title ? this.state.title : this.props.profile.title,
            institution: this.state.institution ? this.state.institution : this.props.profile.institution,
            specialization: this.state.specialization ? this.state.specialization : this.props.profile.specialization,
            location: typeof this.state.location == 'string' ? this.state.location : this.props.profile.location,
            tagLine: this.state.tagLine ? this.state.tagLine : this.props.profile.tagLine,
            bio: this.state.bio ? this.state.bio : this.props.profile.bio
        }
        
    
        this.props.updateProfile(profile, uid);
        this.props.history.push(profileLink);
       
    }

    render() {
        const { auth, profile, updateError } = this.props;
        if (!auth.uid) return <Redirect to='/' />
        
        if (profile) {
           
            return (
                <div className='container'>
                    <form onSubmit={this.handleSubmit} className='white'>
                        <h5 className='grey-text text-darken-3'>Edit Profile</h5>

                        <div className='input-field'>
                            <label htmlFor='firstName'>First Name</label>
                            <input type='text' id='firstName' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='lastName'>Last Name</label>
                            <input type='text' id='lastName' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='imgLink'> Profile Image Link</label>
                            <input type='text' id='imgLink' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='headerImg'> Header Image Link</label>
                            <input type='text' id='headerImg' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='title'>Title</label>
                            <input type='text' id='title' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='institution'>Institution</label>
                            <input type='text' id='institution' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='specialization'>Specialization</label>
                            <input type='text' id='specialization' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='location'>Location</label>
                            <input type='text' id='location' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='tagLine'>Tag Line</label>
                            <input type='text' id='tagLine' onChange={this.handleChange}/>
                        </div>

                        <div className='input-field'>
                            <label htmlFor='bio'>Bio</label>
                            <textarea id='bio' onChange={this.handleChange} data-length="200" className='materialize-textarea'/>
                        </div>
                        
                        <div className='input-field'>
                            <button id='submitProfileUpdate' className='btn lighten-1 z-depth-0'>Submit</button>
                            <div className='red-text center'>
                                { updateError ? <p>{ updateError }</p>: null }
                            </div>
                        </div>
                        
                    </form>
                </div>
            )}
            else {
                return (
                    <div className='container center'>
                        <p>Loading details...</p>
                    </div>
                )
            }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        updateError: null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (profile, uid) => dispatch(updateProfile(profile, uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate)
